import router from '../../router'

const state = {
    RouteName: '',
}
const actions = {
    RouterAuth: ({
        commit
    }) => {
        router.beforeEach((to, from, next) => {
            commit;
            state.RouteName = to.name;

            let token = localStorage.getItem('token')
            if (!token && to.name != 'login') { //如果没有token 并且当前页不是登陆页
                next("/login") //去登陆页
            }
            if (token && to.name == "login") { //如果有token 并且当前页是登陆页
                next("/") //去首页
            }
            next()

        })
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}