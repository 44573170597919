import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
Vue.use(Vuex);
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import '@/assets/styles/style.css' // 全局css样式

import authjson from './assets/function/authjson'; // 权限方法
Vue.prototype.authjson = authjson;


Vue.prototype.$formatNumber = function (value) {
  if (!value) return '0.00';
  return parseFloat(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

// 版本号的判断逻辑，版本号不一致就重新加载
const VUE_APP_VERSION = require('../package.json').version;
const vers = window.localStorage.getItem("Hichat_integral__Version");
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  sessionStorage.clear();
  window.localStorage.setItem("Hichat_integral__Version", VUE_APP_VERSION);
  location.reload();
}

Vue.config.productionTip = false
store.dispatch('login/RouterAuth') //路由守卫


Vue.directive('radioCancel', {
  inserted(el, vDir, vNode) {
    // vDir.value 有指令的参数
    // 按键按下
    el.addEventListener('click', (event) => {
      const e = event || window.event
      // 点击时会执行两次
      if (e.target.tagName === 'SPAN') return
      vNode.componentInstance && vNode.componentInstance.$emit('input', '')
    })
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')