import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{ // 登录
    path: '/login',
    name: 'login',
    component: () =>
      import('@/views/login/login.vue'),
  }, {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: () =>
      import('@/layout/layout.vue'),
    children: [{
      path: '/home', //控制面板
      name: 'home',
      component: () =>
        import('@/views/home/home.vue')
    }, {
      path: '/AgentManagement', // 代理管理
      name: 'AgentManagement',
      component: () =>
        import('@/views/AgentManagement/AgentManagement.vue')
    }, {
      path: '/UserManagement', // 用户管理
      name: 'UserManagement',
      component: () =>
        import('@/views/UserManagement/UserManagement.vue'),
    }, {
      path: '/UserManagement/TransactionRecords', // Hicoin买卖交易记录
      name: 'TransactionRecords',
      component: () =>
        import('@/views/UserManagement/TransactionRecords.vue')
    }, {
      path: '/SalesRecords', //出售记录
      name: 'SalesRecords',
      component: () =>
        import('@/views/SalesRecords/SalesRecords.vue')
    }, {
      path: '/AcquisitionRecords', // 收购记录
      name: 'AcquisitionRecords',
      component: () =>
        import('@/views/AcquisitionRecords/AcquisitionRecords.vue')
    }, {
      path: '/TransactionSettings', // 交易设置
      name: 'TransactionSettings',
      component: () =>
        import('@/views/TransactionSettings/TransactionSettings.vue')
    }, {
      path: '/PermissionManagement', // 权限管理
      name: 'PermissionManagement',
      component: () =>
        import('@/views/PermissionManagement/PermissionManagement.vue')
    }, {
      path: '/ToGrantAuthorization', // 授权
      name: 'ToGrantAuthorization',
      component: () =>
        import('@/views/PermissionManagement/ToGrantAuthorization.vue')
    }, {
      path: '/SystemUserManagement', // 用户管理
      name: 'SystemUserManagement',
      component: () =>
        import('@/views/PermissionManagement/SystemUserManagement.vue'),

    }],
  },

]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})

router.beforeEach()

export default router